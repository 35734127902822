import {
  EntityPageFocusType,
  EntityPagePublicationStatus,
} from '@xing-com/crate-common-graphql-types';

import type { EntityPageContextShared } from '../context/page-context/page-context';
import type { EntityPageSharedFragment } from '../graphql/fragments/entity-page-shared.gql-types';

export const createEntityPageShared = (
  entityPage: EntityPageSharedFragment,
  isWebview = false
): EntityPageContextShared => {
  const isFollowingPage =
    entityPage.userPageContext?.userInteractions?.__typename ===
      'EntityPageUserInteractionFollow' &&
    entityPage.userPageContext.userInteractions.followState?.isFollowing;

  const isEditor = !!entityPage.userPageContext?.permissions?.canEdit;
  const isAdmin = !!entityPage.userPageContext?.permissions?.canAdmin;

  const focusType = entityPage.focusType;
  const isAmbassadorPage = focusType === EntityPageFocusType.AmbassadorPage;
  const isCompanyPage = focusType === EntityPageFocusType.Company;
  const isGroupPage = focusType === EntityPageFocusType.GroupPage;
  const isIndustryPage = focusType === EntityPageFocusType.IndustryPage;
  const isPublisherPage = focusType === EntityPageFocusType.Publisher;
  const isTopicPage = focusType === EntityPageFocusType.TopicPage;
  const isInsiderPage = focusType === EntityPageFocusType.Insider;

  const publicationStatus = entityPage.publicationStatus;
  const isPageCreated =
    publicationStatus === EntityPagePublicationStatus.Created;
  const isPageDraft = publicationStatus === EntityPagePublicationStatus.Draft;
  const isPagePublished =
    publicationStatus === EntityPagePublicationStatus.Published;
  const contentTopicCollection =
    entityPage.entity?.__typename === 'ContentInsiderPage'
      ? entityPage.entity?.topics?.collection
      : undefined;
  const insiderPageName =
    entityPage.entity?.__typename === 'ContentInsiderPage'
      ? entityPage.entity?.xingId?.pageName
      : undefined;

  return {
    globalId: entityPage.globalId,
    pageSlug: entityPage.slug,
    pageId: entityPage.id,
    focusType: focusType as EntityPageFocusType,
    isAmbassadorPage,
    isCompanyPage,
    isGroupPage,
    isIndustryPage,
    isPublisherPage,
    isTopicPage,
    isInsiderPage,
    pageSlogan: entityPage.slogan ?? '',
    isFollowingPage: isFollowingPage,
    companyId: entityPage.context?.companyId,
    cppId: entityPage.context?.cppId,
    // @ts-expect-error TODO: fix this type
    socialProof: entityPage.userPageContext?.socialProof,
    basePath: `/pages${isWebview ? '/webview' : ''}`,
    contractType: entityPage.contract?.type,
    publicationStatus,
    isPageCreated,
    isPageDraft,
    isPagePublished,
    visibility: entityPage.visibility,
    url: entityPage.links?.self,
    pageTitle: entityPage.title,
    upsellActive:
      entityPage.contract?.type === 'FREE' &&
      (isAdmin || isEditor) &&
      entityPage.focusType === EntityPageFocusType.Company,
    logo: entityPage.logoImage?.[0].url ?? '',
    isShowingCompanyBanner: false,
    isEditor,
    isAdmin,
    contentTopicCollection,
    insiderPageName,
  };
};
