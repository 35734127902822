import type { FC } from 'react';

import { usePageContext } from '@xing-com/crate-entity-pages-common';
import { useViewerData } from '@xing-com/hub';

import * as Styled from './header-actions.styles';
import HeaderMoreActions from './header-more-actions';

const HeaderActions: FC = () => {
  const { pageContext } = usePageContext();
  const { data } = useViewerData();
  const viewerIsNotInsider =
    pageContext.insiderPageName !== data?.user?.pageName;

  return (
    <>
      {viewerIsNotInsider ? <Styled.FollowContainer /> : null}
      <HeaderMoreActions />
    </>
  );
};

export default HeaderActions;
