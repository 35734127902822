import * as React from 'react';
import { useIntl } from 'react-intl';

import * as Styled from './absolute-timestamp.styles';
import type { AbsoluteTimestampProps } from './absolute-timestamp.types';
import { parseDate } from './util/parse-date';

export const AbsoluteTimestamp = React.forwardRef<
  HTMLTimeElement,
  AbsoluteTimestampProps
>(
  (
    { className, date: givenDate, variant = 'shortDate', ...props },
    forwardedRef
  ): JSX.Element => {
    const intl = useIntl();
    const date = parseDate(givenDate);
    let timestamp: string;

    switch (variant) {
      case 'longDate':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'long',
        });
        break;
      case 'shortDate':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'short',
        });
        break;
      case 'longDateFull':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
        break;
      case 'shortDateFull':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'short',
          year: '2-digit',
        });
        break;
      case 'longDateTime':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
        });
        break;
      case 'shortDateTime':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'short',
          hour: 'numeric',
        });
        break;
      case 'longDayDate':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          weekday: 'long',
          month: 'long',
        });
        break;
      case 'shortDayDate':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          weekday: 'short',
          month: 'short',
        });
        break;
      case 'longDayDateTime':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          weekday: 'long',
          month: 'long',
          hour: 'numeric',
          minute: 'numeric',
        });
        break;
      case 'shortDayDateTime':
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          weekday: 'short',
          month: 'short',
          hour: 'numeric',
          minute: 'numeric',
        });
        break;
      default:
        timestamp = intl.formatDate(date, {
          day: 'numeric',
          month: 'short',
          year: '2-digit',
        });
    }

    return (
      <Styled.Timestamp
        className={className}
        data-xds="AbsoluteTimestamp"
        dateTime={date.toISOString()}
        ref={forwardedRef}
        title={intl.formatDate(date, {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}
        {...props}
      >
        {timestamp}
      </Styled.Timestamp>
    );
  }
);

AbsoluteTimestamp.displayName = 'AbsoluteTimestamp';
